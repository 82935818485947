
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { TextInput, SelectMulti } from '@bd/components'
import AvatarInput from './AvatarInput.vue'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import { EmployeeRole } from '@bd/api'

export default defineComponent({
  name: 'EmployeeSaveForm',
  components: { TextInput, AvatarInput, SelectMulti },
  setup() {
    const { t } = useI18n()

    const roles = enumConvertToValue(EmployeeRole, 'userRole')

    return {
      t,
      roles,
    }
  },
})
