<template>
  <div class="avatar-input-empty" @click="onSelectImage">
    <template v-if="avatarDataUrl">
      <div class="avatar-input-empty-content">
        <img :src="avatarDataUrl" class="image-input-preview w-100 h-100" />
      </div>
    </template>
    <template v-else-if="!avatarUrl">
      <div class="avatar-input-empty-content">
        <img :src="require('@bd/admin/assets/icons/avatar-input.svg')" />
      </div>
    </template>
    <input
      ref="imageInput"
      class="d-none"
      type="file"
      :accept="allowPhotoFormat"
      @change="onFileChanged"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { CustomInputEvent } from '@bd/admin/types'
import { default as common } from '@bd/admin/config/common.json'
import { useField } from 'vee-validate'
import { adminApi } from '@bd/api'
import { useAppStore } from '@bd/admin/store'
import { AvatarDto } from '@bd/api/admin-api/types'
import { ID, logErr, useBlobUrl } from '@bd/helpers'

export default defineComponent({
  name: 'AvatarInput',
  setup() {
    const store = useAppStore()
    const { value: avatar } = useField<AvatarDto | undefined>('avatar')
    const { value: userId } = useField<ID<'User'> | undefined>('userId')
    const imageInput = ref<HTMLInputElement>()

    const avatarUrl = computed(() => {
      if (avatar.value?.avatarUrl) {
        return avatar.value.avatarUrl
      }

      if (userId.value != null) {
        return store.state.employees?.employeeDetails?.avatarUrl
      }

      return undefined
    })

    const avatarDataUrl = useBlobUrl(avatarUrl, async (url) => {
      const { data } = await adminApi.getAvatarImage(url)
      return data
    })

    const onSelectImage = () => {
      imageInput.value?.click()
    }

    const setAvatar = async (avatarImage: File) => {
      const formData = new FormData()
      formData.append('avatar', avatarImage)

      try {
        if (userId.value) {
          const { data } = await adminApi.updateAvatar({
            avatar: formData,
            userId: userId.value,
          })
          avatar.value = data
        } else {
          const { data } = await adminApi.uploadAvatar(formData)
          avatar.value = data
        }
      } catch (e) {
        logErr(e)
      }
    }

    const onFileChanged = async (event: CustomInputEvent) => {
      if (event.target.files.length) {
        await setAvatar(event.target.files[0])
      }
    }

    return {
      avatarUrl,
      avatarDataUrl,
      onSelectImage,
      imageInput,
      onFileChanged,
      allowPhotoFormat: common.allowPhotoFormat,
    }
  },
})
</script>

<style lang="scss" scoped>
.avatar-input-empty {
  max-width: 280px;
  padding: 10px;
  border-radius: 8px;
  background-color: $white;
  cursor: pointer;
  box-shadow: $default-shadow;
}

.avatar-input-empty-content {
  max-width: 280px;
  border-radius: 8px;
  background-color: $alabaster;
  @include flex;

  img {
    border-radius: 8px;
    min-height: 280px;
    max-width: 280px;
    object-fit: scale-down;
    margin: auto;
  }
}
</style>
