<template>
  <div class="employee_save_form">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="row mb-4">
          <div class="col-md-12">
            <span class="h-4 mb-3">{{ t('employees.profilPhoto') }}</span>
          </div>
        </div>
        <div class="row mb-4 h-100">
          <div class="col h-100">
            <AvatarInput />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row mb-4">
          <div class="col-md-12">
            <span class="h-4 mb-3">{{ t('employees.information') }}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <TextInput
              name="firstName"
              type="text"
              :label="t('first_name')"
              :placeholder="t('employees.firstNamePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
          <div class="col-md-6">
            <TextInput
              name="lastName"
              type="text"
              :label="t('last_name')"
              :placeholder="t('employees.lastNamePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <TextInput
              name="email"
              type="email"
              :label="t('employees.email')"
              :placeholder="t('employees.email')"
              :floating="false"
              whiteBackground
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <TextInput
              name="phoneNumber"
              type="text"
              :label="t('employees.phone')"
              :placeholder="t('employees.phonePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
          <div class="col-md-6">
            <SelectMulti
              name="roles"
              :options="roles"
              :label="t('employees.role')"
              :placeholder="t('select')"
              whiteBackground
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { TextInput, SelectMulti } from '@bd/components'
import AvatarInput from './AvatarInput.vue'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import { EmployeeRole } from '@bd/api'

export default defineComponent({
  name: 'EmployeeSaveForm',
  components: { TextInput, AvatarInput, SelectMulti },
  setup() {
    const { t } = useI18n()

    const roles = enumConvertToValue(EmployeeRole, 'userRole')

    return {
      t,
      roles,
    }
  },
})
</script>

<style lang="scss" scoped></style>
