
import { computed, defineComponent, ref } from 'vue'
import { CustomInputEvent } from '@bd/admin/types'
import { default as common } from '@bd/admin/config/common.json'
import { useField } from 'vee-validate'
import { adminApi } from '@bd/api'
import { useAppStore } from '@bd/admin/store'
import { AvatarDto } from '@bd/api/admin-api/types'
import { ID, logErr, useBlobUrl } from '@bd/helpers'

export default defineComponent({
  name: 'AvatarInput',
  setup() {
    const store = useAppStore()
    const { value: avatar } = useField<AvatarDto | undefined>('avatar')
    const { value: userId } = useField<ID<'User'> | undefined>('userId')
    const imageInput = ref<HTMLInputElement>()

    const avatarUrl = computed(() => {
      if (avatar.value?.avatarUrl) {
        return avatar.value.avatarUrl
      }

      if (userId.value != null) {
        return store.state.employees?.employeeDetails?.avatarUrl
      }

      return undefined
    })

    const avatarDataUrl = useBlobUrl(avatarUrl, async (url) => {
      const { data } = await adminApi.getAvatarImage(url)
      return data
    })

    const onSelectImage = () => {
      imageInput.value?.click()
    }

    const setAvatar = async (avatarImage: File) => {
      const formData = new FormData()
      formData.append('avatar', avatarImage)

      try {
        if (userId.value) {
          const { data } = await adminApi.updateAvatar({
            avatar: formData,
            userId: userId.value,
          })
          avatar.value = data
        } else {
          const { data } = await adminApi.uploadAvatar(formData)
          avatar.value = data
        }
      } catch (e) {
        logErr(e)
      }
    }

    const onFileChanged = async (event: CustomInputEvent) => {
      if (event.target.files.length) {
        await setAvatar(event.target.files[0])
      }
    }

    return {
      avatarUrl,
      avatarDataUrl,
      onSelectImage,
      imageInput,
      onFileChanged,
      allowPhotoFormat: common.allowPhotoFormat,
    }
  },
})
