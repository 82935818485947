<template>
  <div class="container-fluid add-employee">
    <form novalidate @submit="onSubmit">
      <div class="row">
        <div class="col-12">
          <AppContentLayout backAction divider>
            <template #title>
              <span>{{
                isAddEmployeeMode
                  ? t('employees.addTitle')
                  : t('employees.updateTitle')
              }}</span>
            </template>
            <template #back-action>
              <Button
                @click="$router.back()"
                icon="pi pi-chevron-left"
                class="p-button-secondary p-button-circle"
              />
            </template>
            <template #actions>
              <template v-if="isAddEmployeeMode">
                <Button
                  class="p-button-sm p-button-primary"
                  :label="t('add')"
                  type="submit"
                />
              </template>
              <template v-else>
                <Button
                  class="p-button-sm p-button-secondary mr-2"
                  :label="t('employees.remove')"
                  @click="openConfirmDialog(EmployeeEvent.REMOVE)"
                />
                <Button
                  class="p-button-sm p-button-primary"
                  :label="t('save')"
                  type="submit"
                />
              </template>
            </template>
            <EmployeeSaveForm />
          </AppContentLayout>
        </div>
      </div>
    </form>
    <ConfirmDialog
      v-model:visible="confirmDialogState.visible"
      :title="confirmDialogState.title"
      :submitPending="confirmDialogState.submitPending"
      @confirm="onDialogConfirm"
      @reject="onDialogReject"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import EmployeeSaveForm from '@bd/admin/components/Employees/EmployeeSaveForm.vue'
import { EmployeeConfirmDialogState } from '@bd/admin/types'
import { useAppStore } from '@bd/admin/store'
import { EmployeeEvent } from '@bd/admin/config'
import { ConfirmDialog, adminValidationService } from '@bd/components'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { EmployeeEventMessages } from '@bd/admin/config/toast'
import { addOrEditEmployeeSchema } from '@bd/components/yup'
import { toRawDeep } from '@bd/helpers'
import { EmployeeForm } from './types'
import { AddEmployeeDto, EditEmployeeDto } from '@bd/api/admin-api/types'

type Form = Partial<EmployeeForm>

export default defineComponent({
  name: 'EmployeeSave',
  components: {
    AppContentLayout,
    EmployeeSaveForm,
    ConfirmDialog,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const getEditForm = () => {
      const employee = store.state.employees?.employeeDetails
      if (!employee) throw Error('Employee must be loaded beforehand')
      const { blocked, avatarUrl, ...rest } = employee
      return toRawDeep(rest)
    }

    const isAddEmployeeMode = computed(() => route.name === 'EmployeeSave')
    const initialValues = isAddEmployeeMode.value ? {} : getEditForm()

    const form = useForm<Form>({
      initialValues,
      validationSchema: addOrEditEmployeeSchema as never,
    })

    const toastSuccess = () => {
      return isAddEmployeeMode.value
        ? toast.add(EmployeeEventMessages.addSuccess)
        : toast.add(EmployeeEventMessages.updateSuccess)
    }
    const toastError = () => {
      return isAddEmployeeMode.value
        ? toast.add(EmployeeEventMessages.addError)
        : toast.add(EmployeeEventMessages.updateError)
    }

    const saveEmployee = async (employeeData: Form) => {
      const { avatar, ...rest } = employeeData as EmployeeForm

      if (isAddEmployeeMode.value) {
        const payload: AddEmployeeDto = {
          ...rest,
          avatarToken: avatar?.avatarToken,
        }
        await store.dispatch('employees/addEmployee', payload)
      } else {
        const payload: EditEmployeeDto = rest
        await store.dispatch('employees/updateEmployee', payload)
      }
    }

    const onSubmit = form.handleSubmit(async (employeeData, actions) => {
      try {
        await saveEmployee(employeeData)
        await router.push({ name: 'Employees' })
        toastSuccess()
      } catch (err) {
        const errorsData = adminValidationService.handleValidation(err)
        errorsData && actions.setErrors(errorsData)
        toastError()
      }
    })

    const confirmDialogState = ref<EmployeeConfirmDialogState>({
      visible: false,
      title: '',
      submitPending: false,
    })

    const openConfirmDialog = async (actionType: EmployeeEvent) => {
      confirmDialogState.value = {
        actionType: actionType,
        title: t(`confirmDialog.employees.${actionType}.title`),
        visible: true,
        submitPending: false,
      }
    }

    const closeConfirmationDialog = () => {
      confirmDialogState.value.visible = false
    }

    const performConfirmationRequiredAction = async (
      action: () => Promise<void>,
      errorHandler: (err: unknown) => void,
      useDefaultSuccessHandler = true,
    ) => {
      confirmDialogState.value.submitPending = true
      try {
        await action()
        if (useDefaultSuccessHandler) {
          closeConfirmationDialog()
        }
      } catch (err) {
        errorHandler(err)
      } finally {
        confirmDialogState.value.submitPending = false
      }
    }

    const removeEmployee = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('employees/removeEmployee', route.params.id)
          closeConfirmationDialog()
          await router.push({ name: 'Employees' })
          toast.add(EmployeeEventMessages.removeSuccess)
        },
        () => {
          toast.add(EmployeeEventMessages.removeError)
        },
        false,
      )
    }

    const onDialogConfirm = () => {
      const actionType = confirmDialogState.value.actionType
      if (actionType === EmployeeEvent.REMOVE) {
        removeEmployee()
      }
    }

    const onDialogReject = () => {
      confirmDialogState.value.visible = false
    }

    return {
      t,
      EmployeeEvent,
      isAddEmployeeMode,
      onSubmit,
      openConfirmDialog,
      confirmDialogState,
      onDialogConfirm,
      onDialogReject,
      addOrEditEmployeeSchema,
    }
  },
})
</script>

<style lang="scss" scoped>
.add-employee {
  height: 100vh;
  overflow-y: auto;
}
</style>
